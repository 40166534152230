/* Simple spam protection for email addresses using jQuery.
 * Well, the protection isn’t jQuery-based, but you get the idea.
 * This snippet allows you to slightly ‘obfuscate’ email addresses to make it harder for spambots to harvest them, while still offering a readable address to your visitors.
 * E.g.
 * <a href="mailto:foo(at)example(dot)com">foo at example dot com</a>
 * →
 * <a href="mailto:foo@example.com">foo@example.com</a>
 */

$(function() {
 $('a[href^="mailto:"]').each(function() {
  // Remove this line if you don't want to set the email address as link text:
  this.href = this.href.replace('(works_at)', '@').replace('(in)', '.');
  $(this).text(this.href.replace('mailto:', ''));
 });
});
